import './index.css';

if(!!window.location.hash) {
  const hash = window.location.hash.slice(1);
  window.location.href = "/tap/" + hash;
}

// If we're not in a campaign, then load the app.
else {
  const App = import('./App');
  console.log(App);
}



